/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: "/super-admin/dashboard",
    name: "Dashboard",
    slug: "dashboard",
    icon: "MonitorIcon",
    i18n: "Dashboard",
  },

  /*{
      url: "/super-admin/admins",
      name: "Admin Management",
      slug: "admin-accounts",
      icon: "UserIcon",
      i18n: "Admin Management"
    },
    {
      url: "/super-admin/email-templates",
      name: "Email Templates",
      slug: "email-templates",
      icon: "MailIcon",
      i18n: "Email Templates"
    },
    {
      url: "/super-admin/pages",
      name: "Page Management",
      slug: "page-management",
      icon: "FileIcon",
      i18n: "Page Management"
    },*/
  /*{
      url: "/superAdmin/app-user",
      name: "Fresh Clinics Users",
      slug: "app-user",
      icon: "UsersIcon",
      i18n: "Fresh Clinics Users",
    },*/
  {
    url: null,
    name: "Accounts",
    slug: "accounts",
    icon: "UserIcon",
    i18n: "Accounts",
    submenu: [
      {
        url: "/super-admin/organizations",
        name: "Organizations",
        slug: "organization-management",
        i18n: "Organizations",
        icon: "HeadphonesIcon",
      },
      {
        url: "/super-admin/doctors",
        name: "Doctor/NP/PA",
        slug: "franchise-doctors-management",
        i18n: "Doctor/NP/PA",
        icon: "HeadphonesIcon",
      },
      {
        url: "/super-admin/nurses",
        name: "Nurses",
        slug: "pages-register-nurse",
        i18n: "Nurses",
        icon: "UserIcon",
      },
      {
        url: "/super-admin/basic-users",
        name: "Basic Users",
        slug: "pages-basic-users",
        i18n: "Basic Users",
        icon: "UserIcon",
      },
      {
        url: "/super-admin/clinics",
        name: "Clinics",
        slug: "clinic-management",
        icon: "HomeIcon",
        i18n: "Clinics",
      },
      // {
      //   url: "/super-admin/franchises",
      //   name: "Franchise",
      //   slug: "franchise-management",
      //   icon: "HomeIcon",
      //   i18n: "Franchise",
      // },
      // {
      //   url: "/super-admin/doctor-clinics",
      //   name: "Dr Store Clinic",
      //   slug: "doctor-clinics",
      //   icon: "HomeIcon",
      //   i18n: "Dr Store Clinic",
      // },
      // {
      //   url: "/super-admin/community-portal",
      //   name: "Community Members",
      //   slug: "community-portal",
      //   icon: "HomeIcon",
      //   i18n: "Community Members",
      // },
      // {
      //   url: "/super-admin/patients",
      //   name: "Patients",
      //   slug: "patient-management",
      //   icon: "UsersIcon",
      //   i18n: "Patients",
      // },
      // {
      //   url: "/super-admin/derma-specialist",
      //   name: "Derma Specialist",
      //   slug: "derma-specialist",
      //   icon: "HomeIcon",
      //   i18n: "Derma Specialist",
      // },
    ],
  },
  {
    url: null,
    name: "Treatment Settings",
    slug: "treatment-settings",
    icon: "ActivityIcon",
    i18n: "Treatment Settings",
    submenu: [
      {
        url: "/super-admin/products",
        name: "Products",
        slug: "product-management",
        icon: "ShoppingCartIcon",
        i18n: "Products",
      },
      {
        url: "/super-admin/treatment-types",
        name: "Treatment Types",
        slug: "treatment-types",
        icon: "ServerIcon",
        i18n: "Treatment Types",
      },
      {
        url: "/super-admin/safety-video",
        name: "Safety-Video",
        slug: "safety-video",
        i18n: "Safety Video",
        icon: "VideoIcon",
      },
      {
        url: "/super-admin/applicable-areas",
        name: "Applicable Areas",
        slug: "applicable-areas-management",
        icon: "ShoppingCartIcon",
        i18n: "Applicable Areas",
      },
      {
        url: "/super-admin/questionnaire",
        name: "Consent Form Questions",
        slug: "questionnaire",
        icon: "ShoppingCartIcon",
        i18n: "Consent Form Questions",
      },
    ],
  },
  {
    url: null,
    name: "Store Management",
    slug: "store-management",
    icon: "ShoppingCartIcon",
    i18n: "Store Management",
    submenu: [
      // ...process.env.VUE_APP_REGION !== 'US' ? [
      //   {
      //     url: "/super-admin/store/brands",
      //     name: "Brands",
      //     slug: "brand-management",
      //     icon: "PackageIcon",
      //     i18n: "Brands",
      //   },
      // ] : [],
      {
        url: "/super-admin/store/brands",
        name: "Brands",
        slug: "brand-management",
        icon: "PackageIcon",
        i18n: "Brands",
      },
      {
        url: "/super-admin/store/products",
        name: "Products",
        icon: "InboxIcon",
        slug: "products",
        i18n: "Products",
      },
      {
        url: "/super-admin/store/categories",
        name: "Categories",
        slug: "category-management",
        icon: "ShoppingCartIcon",
        i18n: "Categories",
      },
      {
        url: "/super-admin/store/suppliers",
        name: "Suppliers",
        icon: "UsersIcon",
        slug: "suppliers",
        i18n: "Suppliers",
      },
      {
        url: "/super-admin/store/discounts",
        // name: "Discounts",
        name: "Price Adjustment",
        slug: "discounts",
        icon: "CreditCardIcon",
        i18n: "Price Adjustment",
        // i18n: "Discounts",
      },
      {
        url: "/super-admin/store/vouchers",
        name: "Vouchers",
        slug: "vouchers",
        icon: "CreditCardIcon",
        i18n: "Vouchers",
      },
      {
        url: "/super-admin/store/alert",
        name: "Alerts",
        slug: "alerts",
        icon: "AlertCircleIcon",
        i18n: "Alerts",
      },
      {
        url: "/super-admin/store/orders",
        name: "Orders",
        icon: "ShoppingCartIcon",
        slug: "orders",
        i18n: "Orders",
      },
      {
        url: null,
        name: "Pages",
        icon: "HomeIcon",
        slug: "Pages",
        i18n: "Pages",
        submenu: [
          {
            url: "/super-admin/store/update/shopHomepage",
            name: "Homepage",
            icon: "HomeIcon",
            slug: "Homepage",
            i18n: "Homepage",
          },
          {
            url: "/super-admin/store/update/promotions-page",
            name: "Promotions",
            icon: "HomeIcon",
            slug: "Promotions",
            i18n: "Promotions",
          },
        ]
      },
      {
        url: "/super-admin/store/json-settings",
        name: "Json-Settings",
        icon: "HomeIcon",
        slug: "Json-Settings",
        i18n: "Json-Settings",
      },
      {
        url: "/super-admin/store/shop",
        name: "Shop",
        icon: "ShoppingBagIcon",
        slug: "shop",
        i18n: "Shop",
      }
    ],
  },
  {
    url: null,
    name: "Finances",
    slug: "finances",
    icon: "DollarSignIcon",
    i18n: "Finances",
    submenu: [
      {
        url: "/super-admin/invoice/list",
        name: "Treatment Invoices",
        slug: "invoices",
        icon: "BookIcon",
        i18n: "Treatment Invoices",
      },
      {
        url: "/super-admin/transaction-logs",
        name: "Transaction Logs",
        icon: "DollarSignIcon",
        slug: "transaction-logs",
        i18n: "Transaction Logs",
      },
    ],
  },
  {
    url: null,
    name: "Admin",
    slug: "admin",
    icon: "FolderIcon",
    i18n: "Admin",
    submenu: [
      {
        url: "/super-admin/roles",
        name: "User Roles",
        slug: "roles",
        icon: "KeyIcon",
        i18n: "User Roles",
      },
      {
        url: "/super-admin/admins",
        name: "Admin Accounts",
        slug: "admins",
        icon: "KeyIcon",
        i18n: "Admin Accounts",
      },
      {
        url: "/super-admin/compliance",
        name: "Compliance",
        icon: "GridIcon",
        slug: "compliance",
        i18n: "Compliance Checks",
      },
      {
        url: "/super-admin/drugbook",
        name: "Drugbook",
        icon: "BookIcon",
        slug: "drugbook",
        i18n: "Drugbook",
      },
      {
        url: "/super-admin/inventory",
        name: "Inventory",
        icon: "CreditCardIcon",
        slug: "inventory",
        i18n: "Inventory",
      },
      {
        url: "/super-admin/events",
        name: "Eventbrite",
        icon: "CalendarIcon",
        slug: "events",
        i18n: "Eventbrite",
      },
      {
        url: "/super-admin/resource/json-settings",
        name: "ResourceJsonSetting",
        icon: "SettingsIcon",
        slug: "events",
        i18n: "Resource Hub - Json Setting",
      },
      {
        url: "/super-admin/account-creation-logs",
        name: "accountCreationLogs",
        icon: "UsersIcon",
        slug: "account-creation-logs",
        i18n: "Logs",
      },
    ],
  },{
    url: null,
    name: "Insights",
    slug: "admin",
    icon: "FolderIcon",
    i18n: "Insights",
    submenu: [
      {
        url: "/super-admin/clinic-dashboard",
        name: "Clinic Dashboard",
        icon: "MonitorIcon",
        slug: "clinic-dashboard",
        i18n: "Clinic Dashboard",
      },
      {
        url: "/super-admin/clinic-dashboard-demo",
        name: "Demo Clinic Dashboard",
        icon: "MonitorIcon",
        slug: "clinic-dashboard-demo",
        i18n: "Demo Clinic Dashboard",
      },
      {
        url: "/super-admin/nurse-dashboard",
        name: "Nurse Dashboard",
        icon: "MonitorIcon",
        slug: "nurse-dashboard",
        i18n: "Nurse Dashboard",
      },
      {
        url: "/super-admin/insights",
        name: "Supplier Insights",
        icon: "BarChart2Icon",
        slug: "supplier-insights",
        i18n: "Supplier Insights",
      },
      {
        url: "/super-admin/insights-v2",
        name: "Supplier Insights V2",
        icon: "BarChart2Icon",
        slug: "supplier-insights-v2",
        i18n: "Supplier Insights V2",
      },
    ],
  },
  {
    url: null,
    name: "App Settings",
    slug: "app-setings",
    icon: "SlidersIcon",
    i18n: "App Settings",
    submenu: [
      {
        url: "/super-admin/assign-clinic",
        name: "Assign Clinic",
        slug: "assign-clinic",
        i18n: "Assign Clinic",
        icon: "UsersIcon",
      },
      {
        url: "/super-admin/push-notification",
        name: "Push Notification",
        slug: "push-notification",
        i18n: "Push Notification",
        icon: "ListIcon",
      },
      {
        url: "/super-admin/push-notification-templates",
        name: "Push Notification Templates",
        slug: "push-notification-templates",
        i18n: "Push Notification Templates",
        icon: "ListIcon",
      },
      {
        url: "/super-admin/pair-device",
        name: "Pair Device",
        slug: "pair-device",
        i18n: "Pair Devices",
        icon: "SmartphoneIcon",
      },
      {
        url: "/super-admin/email-templates",
        name: "Email Templates",
        slug: "email-templates",
        i18n: "Email Templates",
        icon: "SmartphoneIcon",
      },
      {
        url: "/super-admin/pages",
        name: "Page Management",
        slug: "pages",
        i18n: "Page Management",
        icon: "SmartphoneIcon",
      },
    ],
  },

  /* {
      url: "/super-admin/doctor-aggregated-report",
      name: "Doctor Aggregated Report",
      slug: "doctor-aggregated-report",
      i18n: "Doctor Aggregated Report",
      icon: "BookOpenIcon"
    },*/
  /*{
      url: "/super-admin/invoice",
      name: "Invoice",
      slug: "invoice",
      i18n: "Invoice",
      icon: "CreditCardIcon"
    },*/

  {
    header: "Invoices",
    icon: "LayersIcon",
    i18n: "Invoices",
    items: [
      /*{
              url: "/super-admin/invoice/upcoming-invoices",
              name: "Upcoming Invoices",
              slug: "upcoming-invoices",
              icon: "CreditCardIcon",
              i18n: "Upcoming Invoices"
            },*/
    ],
  },
];
